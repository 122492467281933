import React from "react"
import styled from '@emotion/styled'

import CateringPhoto from "./cateringimage"
import AboutPhoto from "./aboutimage"

const CateringWrapper = styled.div`
  max-width: 1400px;
  margin: 8em auto 6em;
  border-top: 2px solid #edce76;
  border-bottom: 2px solid #edce76;
`

const Contain = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 8em auto;
  padding: 0 2em;

  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: center;
  }
`

const AboutContain = styled.div`
  display: flex;
  flex-direction: column-reverse;
  max-width: 1200px;
  margin: 8em auto;
  padding: 0 2em;

  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: center;
  }
`


const CateringContent = styled.div`
  flex: 1;
  color: white;

  p {
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    color: #a7a5a4;
    -webkit-font-smoothing: antialiased;
    margin-bottom: 3em;
  }

  @media (min-width: 1024px) {
    margin-right: 4em;
  }
`

const AboutContent = styled.div`
  flex: 1;
  color: white;

  p {
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    color: #a7a5a4;
    -webkit-font-smoothing: antialiased;
    margin-bottom: 3em;
  }

  @media (min-width: 1024px) {
    margin-left: 4em;
    padding-left: 2em;
  }
`

const PhotoWrapper = styled.div`
  flex: 1;
  height: auto;
  width: 100%;

  @media (min-width: 1024px) {
    height: 400px;
    width: auto;
  }
`

const Title = styled.h4`
  font-family: "Rozha One", sans-serif;
  font-size: 52px;
  line-height: 114%;
  margin-bottom: 3rem;
  color: #fcfcfc;
  text-align: center;

  @media (min-width: 1024px) {
    text-align: left;
  }

`

const Catering = () => (
  <CateringWrapper id="catering">
    <Contain>
      <CateringContent>
        <Title>Catering &<br/>Group Events</Title>
        <p>Make your next event a memorable one at Olive Bar & Grill. With four distinct areas available to book and a capacity to host up to 250 people, Olive offers a unique setting for your next birthday or anniversary party, rehearsal dinner, wedding reception, corporate event, or special occassion.</p>
      </CateringContent>
      <PhotoWrapper>
        <CateringPhoto />
      </PhotoWrapper>
    </Contain>
    <AboutContain id="about">
      <PhotoWrapper>
        <AboutPhoto />
      </PhotoWrapper>
      <AboutContent>
        <Title>Our Story</Title>
        <p>The team behind Olive Bar & Grill is excited to bring their latest flagship restaurant to Durham. Olive Bar & Grill is a continuing exploration of inventive cocktails and delectable Southern-based cuisine brought to you by Thomas McCormick and acclaimed Chef Robert Whitehead.</p>
      </AboutContent>
    </AboutContain>
  </CateringWrapper>
)

export default Catering
