import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import styled from '@emotion/styled'
import 'typeface-rozha-one'
import scrollTo from 'gatsby-plugin-smoothscroll';

import Clock from "../images/icon-clock.inline.svg"

const HeroImage = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const StyledInnerWraper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2em;
    color: #f7f7f7;
    height: 100%;
  `

  const OpenTime = styled.div`
    color: #ededed;
    font-weight: 600;
    font-family: "Montserrat";
    font-size: 14px;
    letter-spacing: 1px;
    -webkit-font-smoothing: antialiased;
    margin-top: 6rem;
    padding-bottom: .75rem;
    text-transform: uppercase;
    text-shadow: 0px 0px 10px rgba(0,0,0,.9);
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      fill: #ededed;
      height: 24px;
      margin-right: 10px;
    }


    @media (min-width: 597px) {
      margin-top: 10rem;
    }


    @media (min-width: 800px) {
      padding-bottom: 1.25rem;
    }
  `

  const HeroText = styled.h1 `
    font-family: 'Rozha One', serif;
    font-size: 4.3rem;
    text-shadow: 0px 0px 44px rgba(0,0,0,1);
    line-height: 85%;
    padding-top: .25em;
    border-top: 2px solid #edce76;
    color: #fcfcfc;

    @media (min-width: 800px) {
      padding-right: 4rem;
      /* margin-top: 1em; */
      font-size: 5.4em;
    }

    @media (min-width: 1024px) {
      font-size: 6em;
    }
    @media (min-width: 1200px) {
      font-size: 7em;
    }
  `

  const CtaButton = styled.button`
    font-weight: 600;
    font-family: "Montserrat";
    color: #030200;
    background: #758A56;
    color: #fdfdfd;
    padding: 1em 3em;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 2px;
    margin-top: 2.25em;
    -webkit-font-smoothing: antialiased;
    transition: background .3s ease-in, border .3s ease-in;
    border: 2px solid transparent;
    border-radius: 1px;

    &:hover {
      background: rgba(0,0,0,.4);
      border: 2px solid #758A56;
      transition: background .3s ease-out, border .3s ease-out;
    }
  `

  const backgroundFluidImageStack = [
   data.placeholderImage.childImageSharp.fluid,
   `linear-gradient(rgba(0, 0, 0, 0.23), rgba(0, 0, 0, 0.63))`,
 ].reverse()

  return (
      <BackgroundImage
        fluid={backgroundFluidImageStack}
        style={{ width: "auto", height: "100vh", backgroundSize: "cover", marginTop: '-130px', backgroundPosition: "62% center"}}
      >
        <StyledInnerWraper>
          <OpenTime>
            <Clock />
            Open today 4PM&ndash;10PM
          </OpenTime>
          <HeroText>
            Casual<br />
            Durham<br />
            Dining<br />
          </HeroText>
          <CtaButton onClick={() => scrollTo('#menu')}>View Menu</CtaButton>
        </StyledInnerWraper>
      </BackgroundImage>
  )
}

export default HeroImage
