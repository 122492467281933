import React from 'react'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const SectionWrapper = styled.div`
  width: 100%;
`

const SectionTitle = styled.h4`
  color: #fcfcfc;
  text-align: center;
  font-family: "Rozha One", serif;
  font-size: 1.5em;
  display: inline-flex;
  justify-content: center;
  line-height: 100%;

  border-top: 2px solid #edce76;
  border-bottom: 2px solid #edce76;

  padding: 1rem 1.5rem;

  width: 350px;

  margin: 3em 0 2em;
`



const Starters = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const ItemWrapper = styled.div`

  color: #fcfcfc;
  font-family: "Raleway", sans-serif;

  position: relative;
  width: 100%;
  overflow: hidden;

  padding-right: 15px;
  padding-left: 15px;

  @media (min-width: 576px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media (min-width: 1024px) {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  div {
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;

    h5 {
      display: inline-block;
      padding-right: 8px;
      font-size: 16px;
      max-width: 90%;
      text-transform: capitalize;
      margin-bottom: 4px;
    }

    span:first-of-type {
      background-image: linear-gradient(to right,rgba(255,255,255,.5) 33%,rgba(0,0,0,0) 0%);
      background-position: bottom;
      background-size: 3px 1px;
      background-repeat: repeat-x;
      height: 1px;
      width: 100%;
      position: absolute;
      top: 22px;
    }

    span:nth-of-type(2n) {
        position: absolute;
        font-size: 18px;
        font-weight: 400;
        right: 0;
        padding-left: 8px;
        z-index: 0;
        background: #000;
    }

    p {
      clear: both;
      display: block;
      color: #a7a5a4;
      font-size: 13px;
      margin-bottom: 1rem;
    }
  }
`


const EntreeWrapper = styled.div`

  color: #fcfcfc;
  font-family: "Raleway", sans-serif;

  position: relative;
  width: 100%;
  overflow: hidden;

  padding-right: 15px;
  padding-left: 15px;

  @media (min-width: 576px) {
    flex: 0 0 50%;
    max-width: 50%;
  }


  div {
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;

    h5 {
      display: inline-block;
      padding-right: 8px;
      font-size: 16px;
      max-width: 90%;
      text-transform: capitalize;
      margin-bottom: 4px;
    }

    span:first-of-type {
      background-image: linear-gradient(to right,rgba(255,255,255,.5) 33%,rgba(0,0,0,0) 0%);
      background-position: bottom;
      background-size: 3px 1px;
      background-repeat: repeat-x;
      height: 1px;
      width: 100%;
      position: absolute;
      top: 22px;
    }

    span:nth-of-type(2n) {
        position: absolute;
        font-size: 18px;
        font-weight: 400;
        right: 0;
        padding-left: 8px;
        z-index: 0;
        background: #000;
    }

    p {
      clear: both;
      display: block;
      color: #a7a5a4;
      font-size: 13px;
      margin-bottom: 1rem;
    }
  }
`

const Brunch = () => (
  <Wrapper>
    <SectionTitle>
      Salads & Starters
    </SectionTitle>
    <SectionWrapper>
      <Starters>
        <ItemWrapper>
          <div>
            <h5>Whipped Ricotta Toast</h5>
            <span></span>
            <span>7</span>
            <p>Garlic honey, black pepper</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>Local Green Salad</h5>
            <span></span>
            <span>10</span>
            <p>Raw mushrooms, corn, radish, white cheddar dressing</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>Tarte Flambée</h5>
            <span></span>
            <span>14</span>
            <p>Crème fraîche, bacon, onions, emmentaler cheese, date syrup</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>Summer Salad</h5>
            <span></span>
            <span>12</span>
            <p>Tangerine, strawberry, goat cheese, peanut granola, arugula, Champagne vinaigrette</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>Charred Wings</h5>
            <span></span>
            <span>10</span>
            <p>Memphis dry rub, sweet vinegar, bleu cheese ranch, carrot-chili emulsion</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>Crispy Brussel Sprouts</h5>
            <span></span>
            <span>8</span>
            <p>Chili-lime sauce, pickled carrots</p>
          </div>
        </ItemWrapper>
      </Starters>
    </SectionWrapper>
    <SectionTitle>
      Sandwhiches
    </SectionTitle>
    <SectionWrapper>
      <Starters>
        <ItemWrapper>
          <div>
            <h5>Soy-Lacquered Pork Belly Tacos</h5>
            <span></span>
            <span>14</span>
            <p>Cucumber quick pickles, scrambled eggs, cabbage, peanut, garlic aiol</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>8oz Grass Fed Angus Burger</h5>
            <span></span>
            <span>15</span>
            <p>Maple-bourbon bacon jam, cheddar, pickles, crispy onion, garlic aioli</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>Smoked Salmon BLT</h5>
            <span></span>
            <span>14</span>
            <p>Dill creme fraiche, arugula, caper tapanade</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>Crispy Chicken Sandwhich</h5>
            <span></span>
            <span>13</span>
            <p>Cheddar, smoked 1000 island, lettuce, tomato</p>
          </div>
        </ItemWrapper>
      </Starters>
    </SectionWrapper>
    <SectionTitle>
      Entrées
    </SectionTitle>
    <SectionWrapper>
      <Starters>
        <EntreeWrapper>
          <div>
            <h5>Shrimp & Grits</h5>
            <span></span>
            <span>15</span>
            <p>Cheddar grits, kale, tomato gravy</p>
          </div>
        </EntreeWrapper>
        <EntreeWrapper>
          <div>
            <h5>Eggs Benedict</h5>
            <span></span>
            <span>13</span>
            <p>English muffin, chipped ham, poached eggs, hollandaise</p>
          </div>
        </EntreeWrapper>
        <EntreeWrapper>
          <div>
            <h5>Huevos Rancheros</h5>
            <span></span>
            <span>14</span>
            <p>Chorizo, corn and black bean salsa, fried eggs, lime crema, crispy tortilla</p>
          </div>
        </EntreeWrapper>
        <EntreeWrapper>
          <div>
            <h5>Strawberry French Toast</h5>
            <span></span>
            <span>14</span>
            <p>Challah, cinnamon cream, strawberry marmalade, balsamic reduction</p>
          </div>
        </EntreeWrapper>
        <EntreeWrapper>
          <div>
            <h5>Steak & Eggs</h5>
            <span></span>
            <span>18</span>
            <p>Cheddar grits, fried eggs, bearnaise</p>
          </div>
        </EntreeWrapper>
        <EntreeWrapper>
          <div>
            <h5>Spinach & Egg Whites Scramble</h5>
            <span></span>
            <span>14</span>
            <p>Swiss cheese, sweet tomato relish, simple green salad</p>
          </div>
        </EntreeWrapper>
      </Starters>
    </SectionWrapper>
      <SectionTitle>
        Sides
      </SectionTitle>
      <SectionWrapper>
        <Starters>
          <EntreeWrapper>
            <div>
              <h5>Home Fries</h5>
              <span></span>
              <span>6</span>
            </div>
          </EntreeWrapper>
          <EntreeWrapper>
            <div>
              <h5>Bacon</h5>
              <span></span>
              <span>6</span>
            </div>
          </EntreeWrapper>
          <EntreeWrapper>
            <div>
              <h5>Cheddar Grits</h5>
              <span></span>
              <span>6</span>
            </div>
          </EntreeWrapper>
          <EntreeWrapper>
            <div>
              <h5>Two Eggs (Any Style)</h5>
              <span></span>
              <span>6</span>
            </div>
          </EntreeWrapper>
          <EntreeWrapper>
            <div>
              <h5>Toast</h5>
              <span></span>
              <span>6</span>
            </div>
          </EntreeWrapper>
        </Starters>
    </SectionWrapper>
    <SectionTitle>
      Cocktails
    </SectionTitle>
    <SectionWrapper>
      <Starters>
        <ItemWrapper>
          <div>
            <h5>Gran Rosta Coffee</h5>
            <span></span>
            <span>13</span>
            <p>Irish whiskey, fresh coffee, cream, popcorn powder</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>Bloody Mary</h5>
            <span></span>
            <span>10</span>
            <p>Vodka, classic preparation; Add crab claw & shrimp +4</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>Churro Milk Punch</h5>
            <span></span>
            <span>13</span>
            <p>Churro infused whiskey, dark rum, milk, homemade churro</p>
          </div>
        </ItemWrapper>
      </Starters>
    </SectionWrapper>
  </Wrapper>
)

export default Brunch
