import React from 'react'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const SectionWrapper = styled.div`
  width: 100%;
`

const SectionTitle = styled.h4`
  color: #fcfcfc;
  text-align: center;
  font-family: "Rozha One", serif;
  font-size: 1.5em;
  display: inline-flex;
  justify-content: center;
  line-height: 100%;

  border-top: 2px solid #edce76;
  border-bottom: 2px solid #edce76;

  padding: 1rem 1.5rem;

  width: 350px;

  margin: 3em 0 2em;
`



const Starters = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const ItemWrapper = styled.div`

  color: #fcfcfc;
  font-family: "Raleway", sans-serif;

  position: relative;
  width: 100%;
  overflow: hidden;

  padding-right: 15px;
  padding-left: 15px;

  @media (min-width: 576px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media (min-width: 1024px) {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  div {
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;

    h5 {
      display: inline-block;
      padding-right: 8px;
      font-size: 16px;
      max-width: 90%;
      text-transform: capitalize;
      margin-bottom: 4px;
    }

    span:first-of-type {
      background-image: linear-gradient(to right,rgba(255,255,255,.5) 33%,rgba(0,0,0,0) 0%);
      background-position: bottom;
      background-size: 3px 1px;
      background-repeat: repeat-x;
      height: 1px;
      width: 100%;
      position: absolute;
      top: 22px;
    }

    span:nth-of-type(2n) {
        position: absolute;
        font-size: 18px;
        font-weight: 400;
        right: 0;
        padding-left: 8px;
        z-index: 0;
        background: #000;
    }

    p {
      clear: both;
      display: block;
      color: #a7a5a4;
      font-size: 13px;
      margin-bottom: 1rem;
    }
  }
`


const EntreeWrapper = styled.div`

  color: #fcfcfc;
  font-family: "Raleway", sans-serif;

  position: relative;
  width: 100%;
  overflow: hidden;

  padding-right: 15px;
  padding-left: 15px;

  @media (min-width: 576px) {
    flex: 0 0 50%;
    max-width: 50%;
  }


  div {
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;

    h5 {
      display: inline-block;
      padding-right: 8px;
      font-size: 16px;
      max-width: 90%;
      text-transform: capitalize;
      margin-bottom: 4px;
    }

    span:first-of-type {
      background-image: linear-gradient(to right,rgba(255,255,255,.5) 33%,rgba(0,0,0,0) 0%);
      background-position: bottom;
      background-size: 3px 1px;
      background-repeat: repeat-x;
      height: 1px;
      width: 100%;
      position: absolute;
      top: 22px;
    }

    span:nth-of-type(2n) {
        position: absolute;
        font-size: 18px;
        font-weight: 400;
        right: 0;
        padding-left: 8px;
        z-index: 0;
        background: #000;
    }

    p {
      clear: both;
      display: block;
      color: #a7a5a4;
      font-size: 13px;
      margin-bottom: 1rem;
    }
  }
`

const Dinner = () => (
  <Wrapper>
    <SectionTitle>
      Snacks & Starters
    </SectionTitle>
    <SectionWrapper>
      <Starters>
        <ItemWrapper>
          <div>
            <h5>Marinated Olives</h5>
            <span></span>
            <span>6</span>
            <p>White vermouth, orange peel, bay laurel</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>Tomato & Cucumber Gazpacho</h5>
            <span></span>
            <span>12</span>
            <p>Summer melon, basil, olive oil</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>Whipped Ricotta Toast</h5>
            <span></span>
            <span>7</span>
            <p>Garlic honey, black pepper</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>City Ham Wrapped Asparagus Tempura</h5>
            <span></span>
            <span>9</span>
            <p>Parmesan, lemon aioli</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>Crispy Brussel Sprouts</h5>
            <span></span>
            <span>8</span>
            <p>Chili-lime sauce, pickled carrots</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>Tarte Flambée</h5>
            <span></span>
            <span>14</span>
            <p>Crème fraîche, bacon, onions, emmentaler cheese, date syrup</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>Charred Wings</h5>
            <span></span>
            <span>10</span>
            <p>Memphis dry rub, sweet vinegar, bleu cheese ranch, carrot-chili emulsion</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>Crispy Smashed Fingerling Potatoes</h5>
            <span></span>
            <span>12</span>
            <p>Black truffle butter, parmesan, parsley and chive</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>Local Green Salad</h5>
            <span></span>
            <span>10</span>
            <p>Raw mushrooms, corn, radish, white cheddar dressing</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>House Made Charcuterie</h5>
            <span></span>
            <span>15</span>
            <p>House pickles and provisions, house made crackers</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>Summer Salad</h5>
            <span></span>
            <span>12</span>
            <p>Tangerine, strawberry, goat cheese, peanut granola, arugula, Champagne vinaigrette</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>Local Cheeseboard</h5>
            <span></span>
            <span>15</span>
            <p>House condiments and preserves, house made crackers</p>
          </div>
        </ItemWrapper>
      </Starters>
    </SectionWrapper>
    <SectionTitle>
      Raw Bar
    </SectionTitle>
    <SectionWrapper>
      <Starters>
        <ItemWrapper>
          <div>
            <h5>Blue Point Oysters</h5>
            <span></span>
            <span>3</span>
            <p>From Connecticut; Served with pickleback mignonette & bloody mary sauce</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>Beau Soleil Oysters</h5>
            <span></span>
            <span>4</span>
            <p>From Canada; Served with pickleback mignonette & bloody mary sauce</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>Chilled Shrimp - 1/2 Dozen</h5>
            <span></span>
            <span>15</span>
            <p>Served with celery & bloody mary sauce</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>Chilled Shrimp - Dozen</h5>
            <span></span>
            <span>25</span>
            <p>Served with celery & bloody mary sauce</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>Fish Crudo</h5>
            <span></span>
            <span>15</span>
            <p>Preparation changes daily</p>
          </div>
        </ItemWrapper>
      </Starters>
    </SectionWrapper>
    <SectionTitle>
      Entrées
    </SectionTitle>
    <SectionWrapper>
      <Starters>
        <EntreeWrapper>
          <div>
            <h5>Shrimp & Grits</h5>
            <span></span>
            <span>28</span>
            <p>Tomato & red miso gravy, kale, white cheddar, scallion</p>
          </div>
        </EntreeWrapper>
        <EntreeWrapper>
          <div>
            <h5>Chicken & Dumplings</h5>
            <span></span>
            <span>28</span>
            <p>Baby carrots, peas, chicken velouté, coddled egg</p>
          </div>
        </EntreeWrapper>
        <EntreeWrapper>
          <div>
            <h5>8oz Grass Fed Angus Burger</h5>
            <span></span>
            <span>15</span>
            <p>Maple-bourbon bacon jam, cheddar, pickles, crispy onion, garlic aioli, fries</p>
          </div>
        </EntreeWrapper>
        <EntreeWrapper>
          <div>
            <h5>Seared Jumbo Scallops</h5>
            <span></span>
            <span>31</span>
            <p>Sweet corn chowder, bacon lardons, fingerling potato, sherry bacon jus</p>
          </div>
        </EntreeWrapper>
        <EntreeWrapper>
          <div>
            <h5>Grilled 12oz Ribeye</h5>
            <span></span>
            <span>36</span>
            <p>Twice baked potato, parmesan, horseradish, creamed asparagus, squash blossom butter</p>
          </div>
        </EntreeWrapper>
        <EntreeWrapper>
          <div>
            <h5>Catch of the Day</h5>
            <span></span>
            <span>33</span>
            <p>Smoked tomato broth, mussels, squash noodles, lemon basil pistou</p>
          </div>
        </EntreeWrapper>
      </Starters>
    </SectionWrapper>
  </Wrapper>
)

export default Dinner
