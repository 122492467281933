import React from "react"
import styled from '@emotion/styled'

const CallOutsWrapper = styled.div`

  margin-bottom: 4em;

  @media (min-width: 1024px) {
    margin-bottom: 5em;
  }
`

const AlertWrappers = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;

  margin: 0 1em;

  @media (min-width: 1024px) {
    margin: 0 auto;
    position: relative;
    z-index: 99;
    flex-direction: row;
    margin-top: -2em;
    max-width: 1280px;
    padding: 0 2em;
  }

  @media (min-width: 1350px) {
    padding: 0;
  }
`

const Specials = styled.div`

  background: #758A56;
  border-radius: 1px;
  text-align: center;


  width: 100%;
  margin: 2em 0 0;
  padding: 2em;

  @media (min-width: 1024px) {
    margin: 0 2em 0 0;
    height: 355.25px;
  }
`

const Events = styled.div`
  background: #758A56;
  border-radius: 1px;
  text-align: center;

  width: 100%;
  margin: 2em 0 0;
  padding: 2em;

  @media (min-width: 1024px) {
    margin: 0;
    height: 355.25px;
  }
`

const Hours = styled.div`
  background: #758A56;
  border-radius: 1px;
  text-align: center;

  width: 100%;
  margin: 2em 0 0;
  padding: 2em;

  @media (min-width: 1024px) {
    margin: 0 0 0 2em;
    height: 355.25px;
  }
`

const InnerWrap = styled.div`

  padding: .5em;

  p {
    font-family: "Raleway", sans-serif;
    font-weight: 400;
    font-size: 13px;
    padding: .5em 2em 0;
    color: #fefefe;
    -webkit-font-smoothing: antialiased;

    @media (min-width: 1150px) {
      font-size: 14px;
    }

  }


`

const Title = styled.h2`
  font-weight: 600;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #fefefe;
  -webkit-font-smoothing: antialiased;
  padding-bottom: .75em;
  border-bottom: 2px solid #5F7046;
  font-family: "Montserrat", sans-serif;
`

const SubTitle = styled.h3`
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
  color: #fefefe;
  -webkit-font-smoothing: antialiased;
  margin-top: 2em;
  font-family: "Raleway", sans-serif;

  @media (min-width: 1024px) {
    font-size: 14px;
  }

  @media (min-width: 1150px) {
    font-size: 16px;
  }
`

const IntroText = styled.div`
  margin: 3em 2em 0;
  color: #a7a5a4;
  font-weight: 400;
  line-height: 145%;
  -webkit-font-smoothing: antialiased;
  font-family: "Raleway", sans-serif;
  font-size: 18px;

  @media (min-width: 1024px) {
    max-width: 1200px;
    margin: 4em 2em 0;
    font-size: 20px;
  }

  @media (min-width: 1350px) {
    margin: 4em auto 0;
  }
`

const CallOuts = () => (
  <CallOutsWrapper>
    <AlertWrappers>
      <Specials>
        <InnerWrap>
          <Title>
            Today's Specials
          </Title>
          <SubTitle>
            Shrimp & Grits - 26
          </SubTitle>
          <p>Tomato & red miso gravy, kale, white cheddar, scallion</p>
          <SubTitle>
            Gin & Tonic - 12
          </SubTitle>
          <p>London Dry Gin, fancy tonic, grapefruit, rosemary, flower water</p>
        </InnerWrap>
      </Specials>
      <Events>
        <InnerWrap>
          <Title>
            Tonight's Live Music
          </Title>
          <SubTitle>
            Jim Novak - 4pm&mdash;6pm
          </SubTitle>
          <p>Contemporary acoustic jazz</p>
          <SubTitle>
            Still Searchin' - 6pm&mdash;8pm
          </SubTitle>
          <p>Blues from Memphis, TN</p>
          <SubTitle>
            Swamp Dogs - 8pm&mdash;10pm
          </SubTitle>
          <p>Southern-style rock</p>
        </InnerWrap>
      </Events>
      <Hours>
        <InnerWrap>
          <Title>
            Hours & Location
          </Title>
          <SubTitle>
            Monday-Thursday - 11am&mdash;9pm<br/>
            Friday & Saturday - 11am&mdash;11pm<br/>
            Sunday - 11am&mdash;9pm
          </SubTitle>
          <SubTitle>
            Olive Bar & Grill
          </SubTitle>
          <p>135 W. Main St.<br/>Durham, NC</p>
          <p>(919) 555-9183</p>
        </InnerWrap>
      </Hours>
    </AlertWrappers>
    <IntroText>
      <p>Located in beautiful downtown Durham, Olive Bar & Grill brings you the best in Southern cuisine, local craft beer selections and one of the best selections of Bourbon in North Carolina.  Available for private bookings, corporate events, rehearsal dinners and weddings, Olive Bar & Grill can cater to any special occasion. Whether your event requires a tailor made menu or a custom crafted cocktail, our team is happy to work with you in creating your next memorable event.</p>
    </IntroText>
  </CallOutsWrapper>
)

export default CallOuts
