import React, { useState } from 'react'
import styled from '@emotion/styled'

import Dinner from './dinner'
import Brunch from './brunch'
import Drinks from './drinks'


const Menu = () => {
  const [menuCategory, setMenuCategory] = useState('dinner');
  let SelectedCategory;
  if (menuCategory === 'dinner' ) {
    SelectedCategory = Dinner;
  } else if (menuCategory === 'brunch') {
    SelectedCategory = Brunch;
  } else if (menuCategory === 'drinks') {
    SelectedCategory = Drinks;
  }

    const MenuWrapper = styled.div`
      max-width: 1400px;
      margin: 0 1em 4em;

      @media (min-width: 1024px) {
        margin: 0 auto 2em;
      }
    `

    const Title = styled.h3`
      color: #fcfcfc;
      text-align: center;
      font-family: "Rozha One", serif;
      font-size: 2.5em;
      margin-bottom: 3rem;
      line-height: 100%;

      @media (min-width: 1024px) {
        font-size: 3em;
      }


      overflow: hidden;

      span {
        position: relative;
        display: inline-block;
      }

      span:before, span:after {
        content: '';
        position: absolute;
        top: 50%;
        border-bottom: 2px solid #edce76;
        width: 100vw;
        margin: 0 30px;
      }

      span:before {
        right: 100%;
      }
      span:after {
        left: 100%;
      }
    `

    const MenuSelect = styled.ul`
      margin: 0 auto;
      color: #fefefe;
      font-family: "Montserrat", sans-serif;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 600;
      -webkit-font-smoothing: antialiased;
      width: 100%;
      text-align: center;
    `

    const MenuSelection = styled.li`
      cursor: pointer;
      width: 350px;
      padding: 1em 2em;
      border: 2px solid #fefefe;
      display: inline-block;
      text-align: center;
      border-radius: 1px;
      margin-bottom: 1em;

      @media (min-width: 1024px) {
        margin: 0 2em 0;
      }
    `

  return (
    <MenuWrapper id="menu">
      <Title>
        <span>Menu</span>
      </Title>
      <MenuSelect>
        <MenuSelection onClick={() => setMenuCategory('brunch')}>Brunch</MenuSelection>
        <MenuSelection onClick={() => setMenuCategory('dinner')}>Dinner</MenuSelection>
        <MenuSelection onClick={() => setMenuCategory('drinks')}>Drinks</MenuSelection>
      </MenuSelect>
      <SelectedCategory />
    </MenuWrapper>
    )
  }

export default Menu
