import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import styled from '@emotion/styled'
import 'typeface-rozha-one'

const HeroImage = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "events.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const StyledInnerWraper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2em;
    color: #f7f7f7;
    height: 100%;

    @media (min-width: 800px) {
      align-items: flex-end;
    }
  `

  const Title = styled.h4`
    font-family: "Rozha One", sans-serif;
    font-size: 52px;
    text-shadow:
      0 2.8px 2.2px rgba(0, 0, 0, 0.02),
      0 6.7px 5.3px rgba(0, 0, 0, 0.028),
      0 12.5px 10px rgba(0, 0, 0, 0.035),
      0 22.3px 17.9px rgba(0, 0, 0, 0.042),
      0 41.8px 33.4px rgba(0, 0, 0, 0.05),
      0 100px 80px rgba(0, 0, 0, 0.07)
    ;
    line-height: 114%;
    text-align: right;
    margin-bottom: 3rem;
    text-align: center;

    @media (min-width: 800px) {
      text-align: right;
    }

  `

  const Calendar = styled.div`
    width: 100%;

    @media (min-width: 800px) {
      width: 60%;
    }

    @media (min-width: 1024px) {
      width: 45%;
    }

    p {
      font-family: "Raleway", sans-serif;
      color: #a7a5a4;
      -webkit-font-smoothing: antialiased;
      font-size: 13px;
      margin-top: 4em;
      text-align: right;

    }
  `
  const Row = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 1em;
  `
  const Date = styled.div`
    text-align: right;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1.5px;
    font-size: 13px;
    -webkit-font-smoothing: antialiased;
    color: #edce76;

    sup {
      font-size: 8px;
    }

    span {
      display: none;

      @media (min-width: 650px) {
        display: inline;
      }
    }
  `
  const Artist = styled.div`
    flex: 1;
    text-align: right;
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    letter-spacing: 1.5px;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    color: #f7f7f7;
  `

  const backgroundFluidImageStack = [
   data.placeholderImage.childImageSharp.fluid,
   `linear-gradient(rgba(0, 0, 0, 0.43), rgba(0, 0, 0, 0.73))`,
 ].reverse()

  return (
      <BackgroundImage
        fluid={backgroundFluidImageStack}
        style={{ width: "auto", height: "100vh", backgroundSize: "cover", backgroundPosition: "center"}}
      >
        <StyledInnerWraper id="events">
          <Title>
            Live at Olive Bar & Grill
          </Title>
          <Calendar>
            <Row>
              <Date><span>Friday, </span>November 6<sup>th</sup></Date>
              <Artist>Dierks Bentley</Artist>
            </Row>
            <Row>
              <Date><span>Friday, </span>November 13<sup>th</sup></Date>
              <Artist>Carolina Jazz Quartet</Artist>
            </Row>
            <Row>
              <Date><span>Saturday, </span>November 14<sup>th</sup></Date>
              <Artist>Ron White</Artist>
            </Row>
            <Row>
              <Date><span>Friday, </span>November 20<sup>th</sup></Date>
              <Artist>No Guts, No Glory</Artist>
            </Row>
            <Row>
              <Date><span>Saturday, </span>November 21<sup>st</sup></Date>
              <Artist>Doug Benson</Artist>
            </Row>
            <Row>
              <Date><span>Saturday, </span>November 27<sup>2th</sup></Date>
              <Artist>Swamp Trotters</Artist>
            </Row>
            <p>* Artists and dates subject to change. Seating availability not garunteed.</p>
          </Calendar>
        </StyledInnerWraper>
      </BackgroundImage>
  )
}

export default HeroImage
