import React from "react"
import styled from '@emotion/styled'
import scrollTo from 'gatsby-plugin-smoothscroll';

import Logo from '../images/bwlogo.inline.svg'


const ContactWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2em;

`

const Copyright = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 1.25px;
  -webkit-font-smoothing: antialiased;
  color: #a7a5a4;
  text-align: left;
  margin-bottom: 3em;

`

const Contain = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 3em;

  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
`


const ContactForm = styled.div`
  flex: 1;
  color: #a7a5a4;

  h5 {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    letter-spacing: 2px;
    font-size: 13px;
    text-transform: uppercase;
    margin-bottom: 1em;
    color: #f7f7f7;
  }

  p {
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 1.25px;
    -webkit-font-smoothing: antialiased;
  }

  a {
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 1.25px;
    -webkit-font-smoothing: antialiased;
    transition: color .3s ease;
    cursor: pointer;
    display: block;
  }

  a:hover {
    color: #f7f7f7;
    transition: color .3s ease;
  }

  button {
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 1.25px;
    -webkit-font-smoothing: antialiased;
    transition: color .3s ease;
    cursor: pointer;
    display: block;
  }

  button:hover {
    color: #f7f7f7;
    transition: color .3s ease;
  }

  margin-bottom: 2em;

`



const LogoWrapper = styled.div`
  height: auto;
  width: 90px;
  margin-bottom: 2em;

  svg {
    fill: #a7a5a4;
  }
`

const Contact = () => (
  <ContactWrapper id="catering">
    <LogoWrapper>
      <Logo />
    </LogoWrapper>
    <Contain>
      <ContactForm>
        <h5>Site Navigation</h5>
        <button onClick={() => scrollTo('#menu')}>Menu</button>
        <button onClick={() => scrollTo('#events')}>Live Music</button>
        <button onClick={() => scrollTo('#catering')}>Catering</button>
        <button onClick={() => scrollTo('#about')}>About</button>
      </ContactForm>
      <ContactForm>
        <h5>Contact Us</h5>
        <p>Phone</p>
        <p style={{marginBottom: '1em'}}>(919) 555-9183</p>
        <p>Email</p>
        <a href="mailto:info@olivedurham.com">info@olivedurham.com</a>
      </ContactForm>

      <ContactForm>
        <h5>Address</h5>
        <p>
          Olive Bar & Grill<br/>
          1135 W. Main Street<br/>
          Durham, NC 27701
        </p>
      </ContactForm>
      <ContactForm>
        <h5>Hours</h5>
        <p>Monday-Thursday: 11am&mdash;9pm</p>
        <p>Saturday & Sunday: 11am&mdash;11pm</p>
        <p>Sunday: 11am&mdash;9pm</p>
      </ContactForm>
    </Contain>
    <Copyright>&copy;2020 Olive Bar & Grill. All rights reserved.</Copyright>
  </ContactWrapper>
)

export default Contact
