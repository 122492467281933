import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from '../components/hero'
import CallOuts from "../components/callouts"
import Menu from "../components/menu"
import Events from "../components/events"
import Catering from "../components/catering"
import Contact from "../components/contact"


const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Hero />
    <CallOuts />
    <Menu />
    <Events />
    <Catering />
    <Contact />
  </Layout>
)

export default IndexPage
