import React from 'react'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const SectionWrapper = styled.div`
  width: 100%;
`

const SectionTitle = styled.h4`
  color: #fcfcfc;
  text-align: center;
  font-family: "Rozha One", serif;
  font-size: 1.5em;
  display: inline-flex;
  justify-content: center;
  line-height: 100%;

  border-top: 2px solid #edce76;
  border-bottom: 2px solid #edce76;

  padding: 1rem 1.5rem;

  width: 350px;

  margin: 3em 0 2em;
`



const Starters = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const ItemWrapper = styled.div`

  color: #fcfcfc;
  font-family: "Raleway", sans-serif;

  position: relative;
  width: 100%;
  overflow: hidden;

  padding-right: 15px;
  padding-left: 15px;

  @media (min-width: 576px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media (min-width: 1024px) {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  div {
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;

    h5 {
      display: inline-block;
      padding-right: 8px;
      font-size: 16px;
      max-width: 90%;
      text-transform: capitalize;
      margin-bottom: 4px;
    }

    span:first-of-type {
      background-image: linear-gradient(to right,rgba(255,255,255,.5) 33%,rgba(0,0,0,0) 0%);
      background-position: bottom;
      background-size: 3px 1px;
      background-repeat: repeat-x;
      height: 1px;
      width: 100%;
      position: absolute;
      top: 22px;
    }

    span:nth-of-type(2n) {
        position: absolute;
        font-size: 18px;
        font-weight: 400;
        right: 0;
        padding-left: 8px;
        z-index: 0;
        background: #000;
    }

    p {
      clear: both;
      display: block;
      color: #a7a5a4;
      font-size: 13px;
      margin-bottom: 1rem;
    }
  }
`


const Drinks = () => (
  <Wrapper>
    <SectionTitle>
      Cocktails
    </SectionTitle>
    <SectionWrapper>
      <Starters>
        <ItemWrapper>
          <div>
            <h5>Gin & Tonic</h5>
            <span></span>
            <span>13</span>
            <p>London Dry Gin, fancy tonic, grapefruit, rosemary, flower water</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>Margarita Caliente</h5>
            <span></span>
            <span>13</span>
            <p>Jalapeno tequila, mango, lime, agave, chili salt</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>Main Street Sour</h5>
            <span></span>
            <span>13</span>
            <p>Earl Gray Gin, elderflower, lemon, honey, egg white</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>Veracruz</h5>
            <span></span>
            <span>13</span>
            <p>Mezcal, green chartreuse, px sherry, bitters</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>Kips Bay</h5>
            <span></span>
            <span>13</span>
            <p>Dry gin, sweet vermouth, cointreau, bitters</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>Country Swizzle</h5>
            <span></span>
            <span>13</span>
            <p>Silver rum, peach, blue curacao, lime, demerara, mint</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>Well-Known Secret</h5>
            <span></span>
            <span>13</span>
            <p>Vodka, orgeat, lime, cucumber, mint, bitters</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>Remington</h5>
            <span></span>
            <span>13</span>
            <p>Rye whiskey, cherry heering, cocchi americano, bitter</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>Palmetto Express</h5>
            <span></span>
            <span>13</span>
            <p>Vodka, coffee liqueur, hazelnut, cold brew</p>
          </div>
        </ItemWrapper>
      </Starters>
    </SectionWrapper>
    <SectionTitle>
      White Wine
    </SectionTitle>
    <SectionWrapper>
      <Starters>
        <ItemWrapper>
          <div>
            <h5>Pinot Gris</h5>
            <span></span>
            <span>42</span>
            <p>Maysara: Oregon; Cucumber, pear and subtle minerality</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>Muscadet</h5>
            <span></span>
            <span>38</span>
            <p>Michel Delhommeau: France; Oceanic salinity, limestone and bright acidity</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>Viognier</h5>
            <span></span>
            <span>42</span>
            <p>Veritas: Virginia; Botanical bouquet, cantaloupe, orange zest and peaches</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>Sauvignon Blanc</h5>
            <span></span>
            <span>34</span>
            <p>Cotes de Gascogne: France; Bright citrus, floral notes, muskmelon and fresh herbs</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>Chardonnay</h5>
            <span></span>
            <span>42</span>
            <p>Hayes Valley: California; Gentle tannins, coconut and lemon meringue</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>Picpoul de Pinet</h5>
            <span></span>
            <span>30</span>
            <p>Languedoc: France; Bright lime and crisp apple with streaks of honey</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>Rosé</h5>
            <span></span>
            <span>34</span>
            <p>Whispering Angel: France; Blood orange, strawberries and cool melon</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>Chardonnay</h5>
            <span></span>
            <span>95</span>
            <p>Cakebread: California; Green apple, honeydew and herbs</p>
          </div>
        </ItemWrapper>
      </Starters>
    </SectionWrapper>
    <SectionTitle>
      Red Wine
    </SectionTitle>
    <SectionWrapper>
      <Starters>
        <ItemWrapper>
          <div>
            <h5>Nebbiolo</h5>
            <span></span>
            <span>55</span>
            <p>G.D.Vajra: Italy; Fresh blueberry, chili pepper and cedarwood</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>Pinot Noir</h5>
            <span></span>
            <span>38</span>
            <p>Louis Latour: France; Bright cherries and black raspberries harmonize with pine</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>Tempranillo</h5>
            <span></span>
            <span>34</span>
            <p>Cortijo: Spain; Bright cherry, wildflowers and strawberry</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>Cabernet Sauvignon</h5>
            <span></span>
            <span>44</span>
            <p>Bonanza Winery: California; Bold, lush dark fruits and food-friendly grip</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>Merlot</h5>
            <span></span>
            <span>38</span>
            <p>Tinto Negro: Argentina; Dark spruce, currant, raspberry and black cherry</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>Malbec</h5>
            <span></span>
            <span>30</span>
            <p>Languedoc: France; Bright lime and crisp apple with streaks of honey</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>Bordeaux Blend</h5>
            <span></span>
            <span>46</span>
            <p>Ch. Moulinat: France; Herbaceous aromatics, bright merlot fruit, silky tannins</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>Blend</h5>
            <span></span>
            <span>39</span>
            <p>Colliano: Primorska, Slovenia; Soft, fruity scent, cherry and grippy tannins</p>
          </div>
        </ItemWrapper>
        <ItemWrapper>
          <div>
            <h5>Syrah Blend</h5>
            <span></span>
            <span>55</span>
            <p>Stolpman: California; Dark fruit, pomegranate, cracked black pepper</p>
          </div>
        </ItemWrapper>
      </Starters>
      </SectionWrapper>
      <SectionTitle>
        Sparkling
      </SectionTitle>
        <SectionWrapper>
        <Starters>
            <ItemWrapper>
              <div>
                <h5>Prosecco</h5>
                <span></span>
                <span>39</span>
                <p>Belstar: Italy; Sweet pear, nectarine, subtle floral notes</p>
              </div>
            </ItemWrapper>
            <ItemWrapper>
              <div>
                <h5>Rosė</h5>
                <span></span>
                <span>42</span>
                <p>Villa Sandi: Italy; Fresh berries, crisp acidity, zesty and dry</p>
              </div>
            </ItemWrapper>
            <ItemWrapper>
              <div>
                <h5>Champagne</h5>
                <span></span>
                <span>90</span>
                <p>Moet & Chandon: France; Intense green apple, white flower and nuts</p>
              </div>
            </ItemWrapper>
            <ItemWrapper>
              <div>
                <h5>Champagne</h5>
                <span></span>
                <span>95</span>
                <p>Veuve Clicquot: France; Blackberry, white peach, biscuit and kumquats</p>
              </div>
            </ItemWrapper>
            <ItemWrapper>
              <div>
                <h5>Champagne</h5>
                <span></span>
                <span>345</span>
                <p>Dom Perignon: France; Complex, corn silk, toast, coffee, honey</p>
              </div>
            </ItemWrapper>
            </Starters>
          </SectionWrapper>
          <SectionTitle>
            Bottles & Cans
          </SectionTitle>
            <SectionWrapper>
            <Starters>
                <ItemWrapper>
                  <div>
                    <h5>Greenman English Special</h5>
                    <span></span>
                    <span>6</span>
                    <p>Bitter; North Carolina, 5.5%</p>
                  </div>
                </ItemWrapper>
                <ItemWrapper>
                  <div>
                    <h5>Stella Artois</h5>
                    <span></span>
                    <span>5</span>
                    <p>Lager; Belgium, 5.5%</p>
                  </div>
                </ItemWrapper>
                <ItemWrapper>
                  <div>
                    <h5>Budweiser</h5>
                    <span></span>
                    <span>5</span>
                    <p>Lager; Missouri, 5%</p>
                  </div>
                </ItemWrapper>
                <ItemWrapper>
                  <div>
                    <h5>Bud Light</h5>
                    <span></span>
                    <span>5</span>
                    <p>Lager; Missouri, 5%</p>
                  </div>
                </ItemWrapper>
                <ItemWrapper>
                  <div>
                    <h5>Michelob Ultra Lager</h5>
                    <span></span>
                    <span>5</span>
                    <p>Lager; Missouri, 4.2%</p>
                  </div>
                </ItemWrapper>
                <ItemWrapper>
                  <div>
                    <h5>Yuengling</h5>
                    <span></span>
                    <span>5</span>
                    <p>Amber; Pennsylvania, 4.2%</p>
                  </div>
                </ItemWrapper>
                <ItemWrapper>
                  <div>
                    <h5>Modelo Especial</h5>
                    <span></span>
                    <span>5</span>
                    <p>Lager; Mexico, 4.4%</p>
                  </div>
                </ItemWrapper>
                <ItemWrapper>
                  <div>
                    <h5>O'Douls</h5>
                    <span></span>
                    <span>5</span>
                    <p>Non-alcoholic lager; Missouri</p>
                  </div>
                </ItemWrapper>
                </Starters>
              </SectionWrapper>
  </Wrapper>
)

export default Drinks
